import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class HttpinterceptorService {

  constructor(private baseService: BaseService) { }

  /*  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
     request = request.clone({
       setHeaders: {
         'Content-Type': 'application/json',
       }
     });

     return next.handle(request);
   } */

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        // Authorization: `${this.baseService.getToken().tokenType} ${this.baseService.getToken().token}`,
        Authorization: `${this.baseService.getClientToken().tokenType} ${this.baseService.getClientToken().token}`,
        'Content-Type': 'application/json',
      }
    });

    return next.handle(request);
  }
}
