import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from 'src/app/utils/base.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private httpClient: HttpClient, private baseService: BaseService) { }

  saveClientRegistration(data: any): Observable<any> {
    const user = this.baseService.getUserData();
    data.createuserid = user.id;
    return this.httpClient.post(this.baseService.appointmentURL() + 'register', data);
  }

  confirmAccount(data: any): Observable<any> {
    const user = this.baseService.getUserData();
    return this.httpClient.post(this.baseService.appointmentURL() + 'confirm-account', data);
  }

}
