import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

// import { BehaviorSubject } from 'rxjs';
import { Observable, of as observableOf } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  private baseurl = environment.api;

  public emptyAvatar = 'assets/images/avatars/profile.jpg';

  // private logIn = new BehaviorSubject(false);
  // isLoggedIn = this.logIn.asObservable();

  constructor(private router: Router) { }

  static logMessgae(msg): void {
    if (!environment.production) {
      console.log(msg);
    }
  }

  // setLogin(isIn: boolean): void {
  //   this.logIn.next(isIn);
  // }

  getBaseUrl(): string {
    return this.baseurl;
  }

  configsURL(): string {
    return this.getBaseUrl() + 'configuration/';
  }

  appointmentURL(): string {
    return this.getBaseUrl() + 'appointment/';
  }

  dailyAttendanceURL(): string {
    return this.getBaseUrl() + 'dailyattendance/';
  }

  billingUrl(): string {
    return this.getBaseUrl() + 'billing/';
  }

  setSessionData(data: any): void {
    sessionStorage.removeItem('sessionData');
    sessionStorage.setItem('sessionData', JSON.stringify(data));
  }

  private getSesstionData(): any {
    if (sessionStorage.getItem('sessionData')) {
      return JSON.parse(sessionStorage.getItem('sessionData'));
    }
    return false;
  }

  clearSessionData(): void {
    sessionStorage.removeItem('sessionData');
    sessionStorage.clear();
  }

  getToken(): any {
    if (this.getSesstionData()) {
      return this.getSesstionData().auth;
    } else if (this.getClientData()) {
      // return this.getClientData().accessTokenDto;
      return this.getClientData().auth.token;
    }

    return false;
  }


  getClientToken(): any {
    if (this.getClientData()) {
      return this.getClientData().accessToken;
    } else if (this.getClientData()) {
      // return this.getClientData().accessTokenDto;
      return this.getClientData().accessToken.token;
    }

    return false;
  }

  getUserData(): any {
    if (this.getSesstionData()) {
      return this.getSesstionData().user;
      // return this.getSesstionData().useraccount;
    }

    return false;
  }

  setClientData(data: any): void {
    sessionStorage.setItem('clientData', JSON.stringify(data));
    // this.setLogin(true);
  }

  clearClientData(): void {
    sessionStorage.removeItem('clientData');
    // this.setLogin(false);
  }

  logOut(): void {
    this.clearClientData();
    this.router.navigate(['/auth/login']);
  }

  // private getClientData(): any {
  getClientData(): any {
    if (sessionStorage.getItem('clientData')) {
      return JSON.parse(sessionStorage.getItem('clientData'));
    }
    return false;
  }

  getClientInfo(): any {
    if (this.getClientData()) {
      return this.getClientData().useraccount;
    }

    return false;
  }

  checkLogin(): Observable<any> {
    if (this.getClientData()) {
      return observableOf(true);
    }

    return observableOf(false);
  }

}
