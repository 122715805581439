import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from './layout/layout.module';

import { DpDatePickerModule } from 'ng2-date-picker';

import { AppComponent } from './app.component';
import { AboutComponent } from './pages/blog/about.component';
import { SpecialtiesComponent } from './pages/specialties/specialties.component';
import { ServicesComponent } from './pages/services/services.component';
import { HomeComponent } from './pages/home/home.component';

import { RegisterComponent } from './pages/register/register.component';

import { HttpinterceptorService } from './utils/httpinterceptor.service';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { ErrorHandlerService } from './utils/error-handler.service';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsComponent } from './pages/terms/terms.component';

@NgModule({
  declarations: [
    AppComponent, AboutComponent, HomeComponent,
    RegisterComponent, SpecialtiesComponent, ServicesComponent, PrivacyPolicyComponent, TermsComponent
  ],
  imports: [
    BrowserModule, BrowserAnimationsModule, HttpClientModule,
    AppRoutingModule,
    LayoutModule,
    DpDatePickerModule,
    FormsModule, ReactiveFormsModule,
    SimpleNotificationsModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpinterceptorService, multi: true },
    { provide: ErrorHandler, useClass: ErrorHandlerService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
