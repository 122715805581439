import { Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/utils/base.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  currentYear = new Date().getFullYear();
  isLoggedIn: Boolean;

  constructor(private baseService: BaseService) { }

  ngOnInit() {
    this.checkLogin();
    setInterval(() => {
      this.checkLogin();
    }, 1500);
  }

  checkLogin(): void {
    const clientInfo = this.baseService.getClientInfo();
    if (clientInfo) {
      // this.currentUser = userInfo.firstname + ' ' + userInfo.lastname;
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }
  }

}
