import { Component, OnInit } from '@angular/core';
// import * as $ from 'jquery';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(document).ready(function () {

      // Function to animate slider captions
      function doAnimations(elems) {
        // Cache the animationend event in a variable
        const animEndEv = 'webkitAnimationEnd animationend';

        elems.each(function () {
          const $this = $(this);
          const $animationType = $this.data('animation');
          $this.addClass($animationType).one(animEndEv, function () {
            $this.removeClass($animationType);
          });
        });
      }

      // Variables on page load
      const $myCarousel = $('#carouselExampleIndicators');
      const $firstAnimatingElems = $myCarousel.find('.carousel-item:first').find('[data-animation ^= \'animated\']');

      // Initialize carousel
      $myCarousel.carousel();

      // Animate captions in first slide on page load
      doAnimations($firstAnimatingElems);

      // Pause carousel
      $myCarousel.carousel('pause');


      // Other slides to be animated on carousel slide event
      $myCarousel.on('slide.bs.carousel', function (e) {
        const $animatingElems = $(e.relatedTarget).find('[data-animation ^= \'animated\']');
        doAnimations($animatingElems);
      });

    });

    // $('.slider').slick({ infinite: false});
    $(document).ready(function () {
      $('.customer-logos').slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        arrows: false,
        dots: false,
        pauseOnHover: false,
        responsive: [{
          breakpoint: 768,
          settings: {
            slidesToShow: 4
          }
        }, {
          breakpoint: 520,
          settings: {
            slidesToShow: 3
          }
        }]
      });
    });
  }

}
