import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';

import { AboutComponent } from './pages/blog/about.component';
import { SpecialtiesComponent } from './pages/specialties/specialties.component';
import { ServicesComponent } from './pages/services/services.component';
import { RegisterComponent } from './pages/register/register.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsComponent } from './pages/terms/terms.component';

const routes: Routes = [
  {
    path: 'welcome',
    component: HomeComponent
  },
  {
    path: 'blog',
    component: AboutComponent
  },
  {
    path: 'departments',
    component: SpecialtiesComponent
  },
  {
    path: 'services',
    component: ServicesComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'legal/privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'legal/terms-of-use',
    component: TermsComponent
  },
  {
    path: 'auth',
    loadChildren: './auth/auth.module#AuthModule'
  },
  {
    path: 'app',
    loadChildren: './c-app/c-app.module#CAppModule'
  },
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
