import { Injectable, ErrorHandler } from '@angular/core';
// import { Router } from "@angular/router";

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {

  constructor() { }

  // notifyError(title: String, message: String): void {
  notifyError(message: String): void {
    $.notify(
      { title: 'Oops!', message: message },
      { type: 'danger', delay: 5000, placement: { from: 'top', align: 'center' }, mouse_over: 'pause' }
    );
  }

  public handleError(error: any): void {
    // since console.log is disabled in production we need to switch to console.error to always log errors
    console.error(error);
    const code: Number = error.status;
    switch (code) {
      case 400:
        this.notifyError(error.error.message);
        // this.notifyError('Oops! Bad Request', 'Your request cannot be processed. Clear your form and try again');
        break;
      case 401:
        /* Later add logout functionality */
        this.notifyError(error.error.message);
        // this.notifyError('Oops!', 'Sorry you have to logout and login to perform this action');
        break;
      case 403:
        this.notifyError(error.error.message);
        // this.notifyError('Oops!', 'Sorry you don\'t have permission to perform this action');
        break;
      case 404:
        this.notifyError(error.error.message);
        // this.notifyError('Oops!', 'The server can not find the requested resource');
        break;
      case 500:
        this.notifyError(error.error.message);
        // this.notifyError('Error!', 'Something unexpected happened. Please try again');
        break;
      case 502:
        this.notifyError(error.error.message);
        // this.notifyError('Oops!', 'We are sorry we cannot process your request at this time. Try again later');
        break;
      case 503:
        this.notifyError(error.error.message);
        // this.notifyError('Oops!', 'Service Temporary unavailable. Please try again later');
        break;
      case 504:
        this.notifyError(error.error.message);
        // this.notifyError('Oops!', 'Request time out. Please try again');
        break;
      case 0:
        this.notifyError(error.error.message);
        // this.notifyError('Oops!', 'Sorry We can\'t reach the server right now. Please check your internet and try again');
        break;
      default:
        this.notifyError('Application runtime error');
        // this.notifyError('Oops!', 'Application runtime error');
        break;
    }
  }

}
