import { Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/utils/base.service';
// import { Subscription } from 'rxjs';
// declare var $: any;
// import * as HeadRoom from '../../../assets/vendor/headroom/headroom.min.js';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  isLoggedIn: Boolean;
  // checkLogin: Subscription;

  constructor(private baseService: BaseService) {
    // this.checkLogin = baseService.isLoggedIn.subscribe((resp: boolean) => {
    //   if (resp) {
    //     this.isLoggedIn = resp;
    //   }
    // }
    // );
  }

  ngOnInit() {
    // this.setTitle();
    this.checkLogin();
    setInterval(() => {
      this.checkLogin();
    }, 1500);
  }

  logOut(): void {
    this.baseService.logOut();
  }

  checkLogin(): void {
    const clientInfo = this.baseService.getClientInfo();
    this.isLoggedIn = !!clientInfo;
  }

  // ngOnDestroy(): void {
  //   this.checkLogin.unsubscribe();
  // }

}
