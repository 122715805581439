import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppService } from './app.service';
import { NotificationsService } from 'angular2-notifications';
import {BaseService} from './utils/base.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'hims-appt-angular';

  notifOptions: {
    position: ['top', 'center'],
    timeOut: 4000,
    lastOnBottom: false,
    animate: 'fromTop',
    clickIconToClose: true

  };

  constructor(private mainService: AppService, private notify: NotificationsService, private baseService: BaseService) { }

  ngOnInit(): void {
    // this.doLogin();
  }

  doLogin(): void {
    console.log('Log in called');
    const loginData = {
      username: 'jkakabo',
      password: 'stevo177',
      branchId: 1,
    };

    this.mainService.login(loginData).subscribe(
      result => {
        if (result.status === 'SUCCESS') {
          this.notify.success('Connected', 'Connection successful', { timeOut: 1500, showProgressBar: false });
          // this.setUserData(result.data[0]);
          this.baseService.setClientData(result.data[0]);

        } else {
          const notif = this.notify.error('Ooops Error', 'Connection failed. Click to try again', { timeOut: 0, showProgressBar: false });
          notif.click.subscribe((event) => {
            this.doLogin();
          });
        }
      },
      error => {
        const notif = this.notify.error('Sever Error', 'Click to try again', { timeOut: 0, showProgressBar: false });
        notif.click.subscribe((event) => {
          this.doLogin();
        });
      }
    );
  }

  setUserData(uInfo: any): void {
    const info = {
      auth: { tokenType: uInfo.accessToken.tokenType, token: uInfo.accessToken.token },
      user: {
        id: uInfo.useraccount.userid, facility: uInfo.useraccount.facilityid, branch: uInfo.useraccount.facilitybranchid,
        name: [uInfo.useraccount.lastname, uInfo.useraccount.othername, uInfo.useraccount.firstname].join(' ')
      }
    };
    this.mainService.setAppUser(info);
  }

  ngOnDestroy(): void {
    this.mainService.clearSession();
  }
}
