import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { RegistrationService } from './registration.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  clientRegForm: FormGroup;
  loading = false;

  isSuccess: Boolean;
  isError: Boolean;
  errorMsg: String;

  constructor(private fb: FormBuilder, private router: Router, private service: RegistrationService) { }

  ngOnInit(): void {
    this.clientRegForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      passConfirm: ['', Validators.required]
    }, { validators: this.passwordMatch.bind(this) }
    );
  }


  passwordMatch(formGroup: FormGroup) {
    const { value: password } = formGroup.get('password');
    const { value: passConfirm } = formGroup.get('passConfirm');
    return password === passConfirm ? null : { passwordNotMatch: true };
  }

  get regMail() {
    return this.clientRegForm.get('email');
  }

  get regPass() {
    return this.clientRegForm.get('password');
  }

  get regPass2() {
    return this.clientRegForm.get('passConfirm');
  }

  onSave(): void {
    const saveData = this.clientRegForm.value;
    if (this.clientRegForm.invalid) {
      Object.keys(this.clientRegForm.controls).forEach(name => {
        this.clientRegForm.controls[name].markAsTouched();
      });
    } else {
      console.log('In saving ', saveData);
      this.loading = true;
      this.isError = false;
      this.isSuccess = false;
      this.service.saveClientRegistration(saveData).subscribe(
        result => {
          this.loading = false;
          if (result.status === 'SUCCESS') {
            this.isSuccess = true;
            setTimeout(() => {
              this.router.navigate(['/auth/verify'], { skipLocationChange: true });
            }, 2000);
          } else {
            this.isError = true;
            this.errorMsg = 'Registration Error! ' + result.message;
          }
        },
        error => {
          this.loading = false;
          throw(error);
        }
      );

    }
  }

}
