import { Injectable } from '@angular/core';
// import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
// import { map } from 'rxjs/operators';
import { BaseService } from './utils/base.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  // apptUrl = environment.api + 'appointment';
  // configUrl = environment.api + 'configuration';

  constructor(private httpClient: HttpClient, private baseService: BaseService) { }

  setAppUser(auInfo): void {
    console.log('App user', auInfo);
    this.baseService.setSessionData(auInfo);
  }

  clearSession(): void {
    this.baseService.clearSessionData();
  }

  getGenders(): any {
    return this.httpClient.get(this.baseService.configsURL() + 'gendergroup?size=100&sort=name,asc');
  }

  login(info): any {
    return this.httpClient.post(this.baseService.getBaseUrl() + 'authentication/login', info);
  }



}
